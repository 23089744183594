<template>
  <div>
    <template v-if="isCustomerInfoExist">
      <v-form ref="form" v-model="customerFrom" @submit.prevent="FormSubmit()">
        <div class="customerInfo topInfo d-flex align-center">
          <!-- 頭像 -->
          <v-avatar
            v-if="historyInfo.customerInfo?.avatar"
            :image="userInfo.domain + historyInfo.customerInfo.avatar"
            size="50"
            class="me-6"
          />
          <v-avatar v-else color="pink" size="50">
            <span v-if="historyInfo.customerInfo?.name">
              {{ historyInfo.customerInfo.name.length > 2 ? historyInfo.customerInfo.name.substring(0, 2) : historyInfo.customerInfo.name }}
            </span>
            <span v-else-if="historyInfo.chatConversation?.customerName">
              {{ historyInfo.chatConversation.customerName.length > 2 ? historyInfo.chatConversation.customerName.substring(0, 2) : historyInfo.chatConversation.customerName }}
            </span>
          </v-avatar>

          <div class="title ms-3">
            <div style="font-size: 16px; color:#2FB925;">
              <div v-if="isEditMode">
                <v-text-field
                  v-model="formData.name"
                  type="text"
                  hide-details="auto"
                  density="compact"
                  variant="outlined"
                  clearable
                />
              </div>
              <div v-else>
                <span v-if="historyInfo.customerInfo?.name">
                  {{ historyInfo.customerInfo.name }}
                </span>
                <span v-else>
                  {{ historyInfo.chatConversation.customerName }}
                </span>
              </div>
            </div>
            <div v-if="historyInfo.customerInfo?.nickName">
              {{ historyInfo.customerInfo.nickName }}
            </div>
          </div>

          <!-- 編輯 -->
          <v-btn
            v-if="!isEditMode"
            class="text-none edit-button"
            rounded="xl"
            variant="flat"
            @click="isEditMode = true"
          >
            <v-icon>fa-solid fa-user-pen</v-icon>
            <v-tooltip
              activator="parent"
              location="bottom"
            >
              編輯客戶資訊
            </v-tooltip>
          </v-btn>
        </div>

        <div class="mb-6">
          <div class="pb-5 d-flex align-center">
            <div class="me-3">
              郵箱
            </div>
            <div v-if="isEditMode">
              <v-text-field
                v-model="formData.email"
                type="email"
                hide-details="auto"
                density="compact"
                variant="outlined"
                clearable
              />
            </div>
            <div v-else>
              {{ historyInfo.customerInfo?.email ? historyInfo.customerInfo.email : '-' }}
            </div>
          </div>
          <div class="pb-5 d-flex align-center">
            <div class="me-3">
              電話
            </div>
            <div v-if="isEditMode">
              <v-text-field
                v-model="formData.phone"
                type="number"
                hide-details="auto"
                density="compact"
                variant="outlined"
                clearable
              />
            </div>
            <div v-else>
              {{ historyInfo.customerInfo?.phone ? historyInfo.customerInfo.phone : '-' }}
            </div>
          </div>
          <div v-if="!isAddMode" class="pb-5 d-flex align-center">
            <div class="me-3">
              國家
            </div>
            <div v-if="historyInfo.customerInfo">
              {{ historyInfo.customerInfo?.connectionInfo?.region ? historyInfo.customerInfo.connectionInfo.region.country : '-' }}
            </div>
            <div v-else>
              {{ historyInfo.chatConversation.connectionInfo?.region ? historyInfo.chatConversation.connectionInfo.region.country : '-' }}
            </div>
          </div>
          <div v-if="!isAddMode" class="pb-5 d-flex align-center">
            <div class="me-3">
              城市/地區
            </div>
            <div v-if="historyInfo.customerInfo">
              {{ historyInfo.customerInfo?.connectionInfo?.region ? historyInfo.customerInfo.connectionInfo.region.city + " " + historyInfo.customerInfo.connectionInfo.region.area : '-' }}
            </div>
            <div v-else>
              {{ historyInfo.chatConversation.connectionInfo?.region ? historyInfo.chatConversation.connectionInfo.region.city + " " + historyInfo.chatConversation.connectionInfo.region.area : '-' }}
            </div>
          </div>
          <div class="pb-5 d-flex align-center">
            <div class="me-3">
              客戶標籤
            </div>
            <div v-if="isEditMode">
              <CommonTagDisplay
                v-model="formData.tags"
                :all-tags="customerTagList"
                @updateValue="addCustomerTags($event)"
                @removeTag="removeCustomerTag($event)"
                @blur="addCustomerTags($event)"
              />
            </div>
            <div v-else>
              <div v-if="(historyInfo.customerInfo?.customerTags ?? []).length > 0">
                <CommonChip
                  v-for="i in historyInfo.customerInfo.customerTags"
                  :key="i.id"
                  :background-color="i.color"
                  :title="i.name"
                  class="mb-1 mr-2"
                />
              </div>
              <div v-else>
                -
              </div>
            </div>
          </div>
          <div class="pb-5 d-flex">
            <div class="me-3">
              備註
            </div>
            <div v-if="isEditMode">
              <v-text-field
                v-model="formData.remark"
                type="text"
                hide-details="auto"
                density="compact"
                variant="outlined"
                clearable
              />
            </div>
            <div v-else style="max-width:90%;">
              {{ historyInfo.customerInfo?.remark ? historyInfo.customerInfo.remark : '-' }}
            </div>
          </div>
          <v-divider v-if="!isAddMode" />
        </div>
        <div v-if="!isAddMode" class="mb-6">
          <div class="pb-5 d-flex align-center">
            <div class="me-3">
              加入時間
            </div>
            <div v-if="historyInfo.customerInfo">
              {{ monthFormat(historyInfo.customerInfo.createdAt) }}
            </div>
            <div v-else>
              -
            </div>
          </div>
          <div class="pb-5 d-flex align-center">
            <div class="me-3">
              最近對話時間
            </div>
            <div v-if="historyInfo.customerInfo">
              {{ monthFormat(historyInfo.customerInfo.lastChatAt) }}
            </div>
            <div v-else>
              {{ monthFormat(historyInfo.chatConversation.startedAt) }}
            </div>
          </div>
          <div class="pb-5 d-flex align-center">
            <div class="me-3">
              黑名單
            </div>
            <div>
              {{ historyInfo.customerInfo && historyInfo.customerInfo.black ? '已拉黑':'-' }}
            </div>
          </div>
        </div>
        <div v-if="isEditMode" class="d-flex justify-end">
          <v-btn
            class="text-none me-2"
            color="blue-darken-1"
            rounded="xl"
            text="取消"
            variant="tonal"
            @click="cancelEdit()"
          />
          <v-btn
            class="text-none me-2"
            :disabled="!customerFrom"
            color="blue-darken-1"
            rounded="xl"
            variant="tonal"
            type="submit"
            :loading="loading"
          >
            {{ isAddMode ? '新增' : '保存' }}
          </v-btn>
        </div>
      </v-form>
    </template>
    <template v-else>
      <div class="mt-10 text-center">
        <v-btn
          class="text-none"

          rounded="xl"
          variant="tonal"
          @click="addCustomerMode"
        >
          <v-icon class="mr-3">
            fa-solid fa-user-plus
          </v-icon>
          <span>新增客戶資訊</span>
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script setup>
import { editCustomerFn, addCustomerFn } from '@/api/message/customerCenter'

const userInfo = useUserInfo()
const historyInfo = useHistoryInfo()

const props = defineProps({
  mode: {
    type: String,
    default: 'view'
  }
})

onMounted(() => {
  getCustomerTags()
  isCustomerInfoExist.value = historyInfo.customerInfo
})

// 編輯模式
const isEditMode = ref(props.mode === 'edit')
// 新增模式
const isAddMode = ref(false)
// 客戶資訊是否存在
const isCustomerInfoExist = ref(false)
// 防呆【submit btn】
const customerFrom = ref(false)
// loading
const loading = ref(false)

// 表單數據
const originalData = ref({})

const formData = ref({
  name: historyInfo.customerInfo?.name || historyInfo.chatConversation?.customerName || '',
  nickName: historyInfo.customerInfo?.nickName || '',
  email: historyInfo.customerInfo?.email || '',
  phone: historyInfo.customerInfo?.phone || '',
  remark: historyInfo.customerInfo?.remark || '',
  tags: historyInfo.customerInfo?.customerTags || []
})

// 監聽 historyInfo.customerInfo
watch(() => historyInfo.customerInfo, (val) => {
  if (val) {
    isAddMode.value = false
    isCustomerInfoExist.value = true

    originalData.value = {
      name: val.name || historyInfo.chatConversation?.customerName || '',
      nickName: val.nickName || '',
      email: val.email || '',
      phone: val.phone || '',
      remark: val.remark || '',
      tags: val.customerTags || []
    }

    formData.value = { ...originalData.value }
  } else {
    isCustomerInfoExist.value = false
  }
}, { immediate: true, deep: true })

// 監聽 historyInfo.chatConversation
watch(() => historyInfo.chatConversation, (val) => {
  if (!historyInfo.customerInfo && val) {
    originalData.value = {
      name: val.customerName || '',
      nickName: '',
      email: '',
      phone: '',
      remark: ''
    }

    formData.value = { ...originalData.value }
  }
}, { immediate: true, deep: true })

// 新增客戶模式
const addCustomerMode = () => {
  isEditMode.value = true
  isAddMode.value = true
  isCustomerInfoExist.value = true
}

// 客戶標籤
const customerTagList = ref([])
const getCustomerTags = async () => {
  const result = await historyInfo.getCustomerTags()
  customerTagList.value = result
}

// 移除客戶標籤
const removeCustomerTag = (tag) => {
  formData.value.tags = formData.value.tags.filter(item => item.id !== tag.id)
}

// 新增客戶標籤
const addCustomerTags = (tags) => {
  formData.value.tags = tags
}

// 取消編輯
const cancelEdit = () => {
  isEditMode.value = false
  formData.value = { ...originalData.value }
  if (isAddMode.value) {
    isAddMode.value = false
    isCustomerInfoExist.value = false
  }
}

// 編輯客戶提交
const FormSubmit = async () => {
  loading.value = true

  const customerId = historyInfo.customerInfo?.customerId ? historyInfo.customerInfo.customerId : historyInfo.chatConversation.customerId

  // tags 有變動才傳送
  if (JSON.stringify(formData.value.tags) === JSON.stringify(originalData.value.tags)) {
    delete formData.value.tags
  } else {
    formData.value.tags = formData.value.tags.map(item => item.id)
  }

  const addFormData = {
    ...formData.value,
    customerId
  }

  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      ElMessage.success({ message: isAddMode.value ? '新增成功' : '編輯成功' })
      isEditMode.value = false
      isAddMode.value = false
      historyInfo.getCustomerInfo(customerId)
    }
    loading.value = false
  }

  if (isAddMode.value) {
    await addCustomerFn(addFormData, onRes)
  } else {
    await editCustomerFn(customerId, formData.value, onRes)
  }
}

</script>

  <style lang="scss" scoped>
    .customerInfo {
      padding: 20px 25px;
      margin: 20px 0 30px 0;
    }

    .edit-button {
      margin-left: auto;
    }
  </style>
