<template>
  <v-chip
    :closable="closable"
    class="mb-1 cuschip"
    :color="backgroundColor"
    close-icon="fa-solid fa-xmark"
    :style="`border-radius:5px; background-color:${backgroundColor}`"
    :size="size"
    @click:close="closeChip"
  >
    <span class="text">{{ title }}</span>
  </v-chip>
</template>

<script setup>
const props = defineProps({
  closable: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    default: 'primary'
  },
  backgroundColor: {
    type: String,
    default: 'primary'
  },
  title: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: 'small'
  }
})

const emit = defineEmits(['closeChip'])

// 當用戶點擊關閉按鈕時觸發 'closeChip' 事件
const closeChip = () => {
  emit('closeChip')
}

</script>
<style lang="scss" scoped>
.cuschip{
    :deep(.v-chip__close){
        display:none;
    }

    &:hover{
      :deep(.v-chip__close){
          display:flex;
      }
    }
}

.text{
  /*filter: grayscale(1) contrast(999) invert(1)*/
    color: white;
}
</style>
