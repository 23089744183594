<template>
  <div>
    <v-tabs v-model="tab" color="#2F88FF">
      <v-tab value="convRecord">
        <!-- 對話紀錄 -->
        {{ t('convDetail_tabConvRecord') }}
      </v-tab>
      <v-tab value="userInfo">
        <!-- 客戶資訊 -->
        {{ t('convDetail_tabUserInfo') }}
      </v-tab>
    </v-tabs>
    <v-card-text>
      <v-window v-model="tab">
        <!-- 對話紀錄 -->
        <v-window-item value="convRecord">
          <ConvDetailConvRecord />
        </v-window-item>
        <!-- 客戶資訊 -->
        <v-window-item class="userInfoPage" value="userInfo">
          <ConvDetailUserInfo
            :mode="mode"
          />
        </v-window-item>
      </v-window>
    </v-card-text>
  </div>
</template>

<script setup>
const { t } = useI18n()

// 接收參數
const props = defineProps({
  tab: {
    type: String,
    default: 'convRecord'
  },
  mode: {
    type: String,
    default: 'view'
  }
})

const tab = ref(props.tab)
const mode = ref(props.mode)
</script>

<style lang="scss" scoped>
    .userInfoPage {
        width: 100%;
        max-width: 90%;
        margin: 0 auto;
    }
    :deep(.v-slide-group__content) {
        border-bottom: 1px solid #ccc;
    }
    :deep(.v-card-text) {
        height: calc(100vh - 153px);
        overflow: scroll;
        padding: 0;
    }
    :deep(.topInfo) {
        background: #e9f5ff;
        border-radius: 15px;

        .title {
        font-size: 16px;
        font-weight: bold;
        }
        .subText {
        font-size: 14px;
        }
    }

    :deep(.v-expansion-panel__shadow) {
        box-shadow: none;
    }
    :deep(.v-expansion-panel-title) {
        background: #e9f5ff;
        padding: 16px 20px;
        border-radius: 10px;
    }
    :deep(.v-expansion-panel-text__wrapper) {
        padding: 10px;
    }
</style>
