<template>
  <v-container v-if="!selectorVisible">
    <v-row align="center" justify="start">
      <div class="selected-tags d-flex align-center mb-2">
        <!-- 已選標籤顯示 -->
        <div v-if="(modelValue ?? []).length >= 0">
          <CommonChip
            v-for="(item) in modelValue"
            :key="item.id"
            :title="item.name"
            :background-color="item.color"
            closable
            class="chip-item"
            @closeChip="removeTag(item)"
          />
        </div>

        <!-- "+" 按鈕 -->
        <div
          v-if="(modelValue ?? []).length === 0 || (modelValue ?? []).length !== (allTags ?? []).length"
          class="add"
          @click="showSelector"
        >
          +
        </div>
      </div>
    </v-row>
  </v-container>

  <!-- CommonTagSelector 下拉选单 -->
  <LazyCommonTagSelector
    v-if="selectorVisible"
    :value="modelValue ?? []"
    :all-tags="allTags ?? []"
    @update:modelValue="emit('updateValue', $event)"
    @blur="blurSelector"
  />
</template>

<script setup>

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  },
  allTags: {
    type: Array,
    default: () => []
  }
})

// 標籤選擇器狀態
const selectorVisible = ref(false)

const emit = defineEmits(['updateValue', 'removeTag', 'blur'])

// 刪除標籤
const removeTag = (item) => {
  emit('removeTag', item) // 發送事件更新父組件
}

// 點擊 "+" 按鈕顯示標籤選擇器
const addAction = () => {
  selectorVisible.value = true
  nextTick(() => {
    const tagSelector = $refs.tagSelector
    if (tagSelector) {
      tagSelector.openCombobox()
    }
  })
}

// 關閉下拉選單
const blurSelector = (newTags) => {
  selectorVisible.value = false
  emit('blur', newTags)
}

// 顯示下拉選單
const showSelector = () => {
  selectorVisible.value = true
}

</script>

<style lang="scss" scoped>
.chip-item {
  margin-right: 10px; /* 為每個 Chip 添加間隔 */
  position: relative; /* 確保關閉按鈕不會影響內容排列 */
  transition: all 0.3s ease; /* 添加過渡效果避免跳動 */

  :hover {
    cursor: pointer;
    opacity: 0.95;
  }

  :deep(.v-input__control) {
    padding-left: 0;
  }
  :deep(.v-chip__close) {
    color: gray;
  }
}

.selected-tags {
  display: flex;
  flex-wrap: wrap;
}

.add{
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  display: flex ;
  justify-content: center;
  align-items: center;
  width:50px;
  &:hover{
    cursor:pointer;
    background-color: #f5f5f5;
  }
}

</style>
