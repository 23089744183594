import { useRuntimeConfig } from '#app'
const { useCustomFetch } = useFetchBase()

// request(REQ) Api  --------------------------------
const base = '/api/message'
const modules = '/history'
const path = base + modules

const historyREQ = path + '/q' // 查詢歷史紀錄

// request(REQ) Function  --------------------------------

/**
 * 查詢歷史紀錄
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
const getHistoryREQFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(historyREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}

export {
  getHistoryREQFn
}
