import { useRuntimeConfig } from '#app'
const { useCustomFetch } = useFetchBase()

// request(REQ) Api  --------------------------------
const base = '/api/message'
const modules = '/customerCenter'
const path = base + modules

const getCustomerListREQ = path + '/q' // 獲取客戶列表
const getCustomerREQ = path + '/{0}' // 獲取客戶列表
const getCustomerDetailREQ = path + '/{0}/details' // 獲取客戶列表
const editCustomerREQ = path + '/{0}' // 更新客戶
const deleteCustomerREQ = path // 刪除客戶
const deleteAllCustomerREQ = path + '/all' // 刪除全部客戶
const addCustomerTagsREQ = path + '/{0}/tags' // 添加客戶標籤
const addCustomerTagsBatchREQ = path + '/tags' // 批量添加客戶標籤

// request(REQ) Function  ---------------------------

/**
 * 獲取客戶列表
 * @param {*} params 查詢參數
 * @param {*} callback 回調函數
 * @returns 資料
 */
const getCustomerREQFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(getCustomerListREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取客戶信息
 * @param {*} customerId 客戶id
 * @param {*} callback 回調函數
 * @returns 資料
 */
const getCustomerFn = (customerId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + stringFormat(getCustomerREQ, customerId), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取客戶詳情
 * @param {*} customerId 客戶id
 * @param {*} callback 回調函數
 * @returns 資料
 */
const getCustomerDetailsFn = (customerId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + stringFormat(getCustomerDetailREQ, customerId), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 新增客戶
 * @param {*} formData 表單
 * @param {*} callback 回調函數
 * @returns
 */
const addCustomerFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + path, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 更新客戶
 * @param {*} customerId 客戶id
 * @param {Object} formData 表單提交
 * @param {*} callback 回調函數
 * @returns
 */
const editCustomerFn = (customerId, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + stringFormat(editCustomerREQ, customerId), {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除客戶
 * @param {*} params 參數 (ids = 客戶id)
 * @param {*} callback 回調函數
 * @returns
 */
const deleteCustomerFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(deleteCustomerREQ, params), {
    method: 'DELETE',
    onResponse: callback
  })
}

/**
 * 刪除所有客戶
 * @param {*} callback 回調函數
 * @returns
 */
const deleteAllCustomerFn = (callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + deleteAllCustomerREQ, {
    method: 'DELETE',
    onResponse: callback
  })
}

/**
 * 添加客戶標籤
 * @param {*} formData 表單
 * @param {*} callback 回調函數
 * @returns
 */
const addCustomerTagsFn = (id, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(addCustomerTagsREQ, id)
  return useCustomFetch(api, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

const addCustomerTagsBatchFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addCustomerTagsBatchREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

export {
  getCustomerREQFn,
  getCustomerFn,
  getCustomerDetailsFn,
  addCustomerFn,
  editCustomerFn,
  deleteCustomerFn,
  deleteAllCustomerFn,
  addCustomerTagsFn,
  addCustomerTagsBatchFn
}
