import { defineStore } from 'pinia'
import { getHistoryREQFn } from '@/api/message/history'
import { getChannelsREQFn } from '@/api/message/channels'
import { getConAllInfoFn, getOtherConInfoFn, getChatHistoryMsgFn } from '@/api/message/conversations'
import { getCustomerFn, getCustomerDetailsFn } from '@/api/message/customerCenter'
import { getConversationTagsFn } from '@/api/message/conversationTag'
import { getAllCustomerTagListFn } from '@/api/message/customerTag'
import { useUserInfo } from '@/stores/useInfo'

export const useHistoryInfo = defineStore('historyInfo', {
  state: () => ({
    // 搜尋的預設日期
    historyLists: [],
    progressLoading: false,
    listTotal: 0,
    channelMap: new Map(),
    channelInfo: [], // 渠道
    endReasonMap: {
      customer: '訪客結束',
      agent: '客服結束',
      timeout: '超時結束',
      queue: '排隊結束',
      queue_timeout: '排隊超時結束',
      blockade: '封鎖結束'
    },
    getHistoryDetailParams: {
      pageNum: 0,
      pageSize: 20
    },
    getOtherConInfoLoading: false,
    chatConversation: {}, // 【單筆】對話紀錄
    chatMessageList: {}, // 對話訊息(含total)
    chatMessage: {}, // 對話訊息
    showMoreBtn: false,
    totalChatInfo: null,
    historyConversation: [], // 【全部】對話紀錄
    onCurrentPanelIndex: [], // 預設打開的對話詳情
    customerInfo: {}, // 客戶資訊
    conversationTags: [], // 對話標籤
    customerTags: [] // 客戶標籤
  }),
  getters: {
    agentUsersMap: () => {
      const userInfo = useUserInfo()
      return userInfo.agentUsersMap
    }
  },
  actions: {
    async getChannel (params) { // 獲取渠道名稱
      const res = await getChannelsREQFn(params)
      const data = res.data.value
      if (data.code === 200) {
        this.channelInfo = data.data.data

        data.data.data.forEach((i) => {
          this.channelMap.set(i.id, i.channelName)
        })
      }
    },
    async getHistoryList (params) { // 獲取歷史列表
      this.progressLoading = true
      this.historyLists = []

      const res = await getHistoryREQFn(params)
      const data = res.data.value
      if (data.code === 200) {
        this.progressLoading = false
        this.listTotal = data.data.total

        const getData = data.data.data
        console.log('getData', getData)

        getData.forEach((i) => {
          const info = {
            channelId: this.channelMap.has(i.channelId) ? this.channelMap.get(i.channelId) : i.channelId, // 接入渠道
            channelType: i.channelType, // 對話渠道(web、line、tg)
            customerId: i.customerId, // 用戶id
            customerName: i.customerName, // 用戶姓名
            sourceRegion: i.connectionInfo && i.connectionInfo.region, // (country國家、area區域、province省份、city城市、isp)
            customerIp: i.connectionInfo && i.connectionInfo.ip, // IP
            sourceDevice: i.connectionInfo && i.connectionInfo.device.substring(0, 8), // TODO設備(web/mobile) (確認後端傳的值，確認後移除substring)
            sourcePlatform: i.connectionInfo && i.connectionInfo.platform, // 系統
            browser: i.connectionInfo && i.connectionInfo.browser, // 瀏覽器
            id: i.id, // 單號 .substring(0, 8) + '...'
            chat: {
              startedAt: i.startedAt ? monthFormat(i.startedAt) : null, // 開始時間
              finishedAt: i.endedAt ? monthFormat(i.endedAt) : null, // 結束時間
              duration: i.duration ? secondsToStr(i.duration) : null // 服務時長
            },
            queue: {
              createdAt: i.queueInfo ? monthFormat(i.queueInfo.startedAt) : null, // 排隊(開始時間)
              duration: i.queueInfo ? secondsToStr(i.queueInfo.duration) : null // 排隊時長
            },
            firstReplyDuration: i.chatConversationStatistics ? secondsToStr(i.chatConversationStatistics.firstReplyDuration) : null, // 首次回復時長
            longestReplyDuration: i.chatConversationStatistics ? secondsToStr(i.chatConversationStatistics.longestReplyDuration) : null, // 最久回復時長
            agentId: this.agentUsersMap.has(i.agentId) ? this.agentUsersMap.get(i.agentId).username : '-', // 接待客服
            conversationTags: i.conversationTags ? i.conversationTags.filter(el => el) : null, // 對話標籤 (若標籤被刪除會回傳null，這邊過濾)
            customerTags: i.customerTags ? i.customerTags.filter(el => el) : null, // 客戶標籤
            remark: i.remark, // 備註
            endReason: this.endReasonMap[i.endReason] || i.endReason,
            satisfaction: i.satisfaction // 滿意度評價
          }
          this.historyLists.push(info)
        })

        return data.code
      }
    },
    async getConversationDetail (convId) { // 獲取歷史會話詳情
      let data = null
      this.historyConversation = []

      const res = await getConAllInfoFn(convId, this.getHistoryDetailParams)
      data = res.data.value

      /** data.data 回傳資料
       * @returns chatConversation 【單筆】 對話紀錄
       * @returns chatMessageList 對話訊息
       * @returns customer 客戶資訊
       * @returns historyConversation 【全部】對話紀錄
       */

      if (data.code === 200) {
        // 客戶資訊
        this.customerInfo = data.data.customer

        // 歷史對話紀錄
        data.data.historyConversation.forEach((i) => {
          i.channelName = this.channelMap.has(i.channelId) ? this.channelMap.get(i.channelId) : i.channelId // 接入渠道
          i.agentName = this.agentUsersMap.has(i.agentId) ? this.agentUsersMap.get(i.agentId) : null // 接待客服
          i.sort = i.startedAt ? i.startedAt : i.queueInfo.startedAt
          this.historyConversation.push(i)
        })

        this.processConversationDetail(data.data)
      }
    },
    async getConversationInfo (convId) { // 獲取歷史會話資訊
      let data = null

      const res = await getOtherConInfoFn(convId, this.getHistoryDetailParams)
      data = res.data.value

      /** data.data 回傳資料
       * @returns chatConversation 【單筆】 對話紀錄
       * @returns chatMessageList 對話訊息
       */

      if (data.code === 200) {
        this.processConversationDetail(data.data)
      }
    },
    async getConversationDetailByCustomerId (customerId) { // 獲取歷史詳情
      let data = null

      // 當筆對話紀錄
      this.historyConversation = []

      const res = await getCustomerDetailsFn(customerId)
      data = res.data.value
      /** data.data 回傳資料
       * @returns chatConversation 【單筆】 對話紀錄
       * @returns chatMessageList 對話訊息
       * @returns customer 客戶資訊
       * @returns historyConversation 【全部】對話紀錄
       */

      if (data.code === 200) {
        // 客戶資訊
        this.customerInfo = data.data.customer

        // 歷史對話紀錄
        data.data.historyConversation.forEach((i) => {
          i.channelName = this.channelMap.has(i.channelId) ? this.channelMap.get(i.channelId) : i.channelId // 接入渠道
          i.agentName = this.agentUsersMap.has(i.agentId) ? this.agentUsersMap.get(i.agentId) : null // 接待客服
          i.sort = i.startedAt ? i.startedAt : i.queueInfo.startedAt
          this.historyConversation.push(i)
        })

        this.processConversationDetail(data.data)
      }
    },
    processConversationDetail (data) { // 處理對話詳情
      this.getOtherConInfoLoading = false

      // 單筆詳情 (對話資訊、設備資訊)
      this.chatConversation = data.chatConversation

      // 對話訊息
      data.chatMessageList.data.forEach((msg) => {
        this.processMsg(msg)
      })
      this.chatMessage = data.chatMessageList.data
      this.chatMessageList = data.chatMessageList

      // 排序
      this.historyConversation.sort((a, b) => b.sort - a.sort)

      // 預設打開的對話詳情
      this.onCurrentPanelIndex = this.historyConversation.findIndex(element => element.id === this.chatConversation.id)

      // 判斷顯示【查看更多歷史對話】
      const total = data.chatMessageList.total
      const pageSize = this.getHistoryDetailParams.pageSize
      const totalPage = Math.floor(total / pageSize)
      this.totalChatInfo = totalPage < 0 ? 1 : (total % pageSize ? totalPage + 1 : totalPage)
      this.showMoreBtn = this.totalChatInfo > 1
    },
    processMsg (msg) { // 處理訊息
      // 比對客服資料
      const agentInfo = this.agentUsersMap.get(msg.agentId)

      if (agentInfo !== null) {
        msg.agentAvatar = agentInfo && agentInfo.avatar
        msg.userName = agentInfo && agentInfo.username
      }

      // 區分客服、訪客
      if (msg.senderType === 'customer') {
        msg.group = 'left'
      } else if (msg.senderType === 'system' && msg.msgType === 'welcome') {
        msg.group = 'center'
      } else {
        msg.group = 'right'
      }
    },
    async getChatHistoryMsg (pageNum, conId, channelId) { // 查看歷史訊息
      const params = {
        channelId,
        pageNum,
        pageSize: this.getHistoryDetailParams.pageSize
      }
      const res = await getChatHistoryMsgFn(conId, params)
      const data = res.data.value
      if (data.code === 200) {
        const newData = data.data.data

        newData.forEach((msg) => {
          this.processMsg(msg)
        })

        // 去重 + 加入訊息
        const filteredHistoryData = newData.filter(
          msg => !this.chatMessage.some(item => item.id === msg.id)
        )

        this.chatMessage.unshift(...filteredHistoryData)

        const totalPage = Math.floor(data.data.total / data.data.pageSize)
        this.showMoreBtn = pageNum < totalPage
      }
    },
    async getCustomerInfo (customerId) { // 獲取客戶資訊
      const res = await getCustomerFn(customerId)
      const data = res.data.value
      if (data.code === 200) {
        this.customerInfo = data.data
      }
    },
    async getConversationTags (params) { // 獲取對話標籤
      if (this.conversationTags && this.conversationTags.length > 0) {
        return this.conversationTags
      }

      const param = {
        pageNum: params.pageNum || 1,
        pageSize: params.pageSize || 10
      }
      const res = await getConversationTagsFn(param)
      const data = res.data.value
      if (data.code === 200) {
        this.conversationTags = data.data.data
      }
      return this.conversationTags
    },
    async getCustomerTags () { // 獲取客戶標籤
      if (this.customerTags && this.customerTags.length > 0) {
        return this.customerTags
      }

      const param = {
        pageNum: 0,
        pageSize: 50
      }
      const res = await getAllCustomerTagListFn(param)
      const data = res.data.value
      if (data.code === 200) {
        this.customerTags = data.data.data
      }
      return this.customerTags
    }

  }
})
