<template>
  <div class="agentConv">
    <template v-if="historyInfo.chatMessage.length > 0">
      <div v-if="historyInfo.showMoreBtn" class="moreConInfo">
        <button @click="viewMoreChatInfo()">
          <!-- 查看更多 -->
          {{ t('convDetail_viewMore') }}
          <i class="fa-solid fa-chevron-up" />
        </button>
      </div>

      <template v-for="(msg,index) in historyInfo.chatMessage" :key="msg.id">
        <!-- 分配成功提示語 -->
        <div v-if="msg.msgType === 'welcome' " class="welcomeMsg mb-10">
          {{ msg.content }}
        </div>

        <div v-if="msg.msgType !== 'welcome' && msg.msgType !== 'finish'" class="msgBlock">
          <!-- 頭像 -->

          <!-- 訪客 -->
          <template v-if="(msg.group == 'left' && index == 0) || (msg.group == 'left' && index > 0 && msg.group !== historyInfo.chatMessage[index - 1].group)">
            <div class="msgTitle">
              <!-- 常見問題 -->
              <template v-if="msg.msgType == 'common_problem'">
                <v-avatar
                  :image="'/images/question.png'"
                  color="pink"
                  size="25"
                  class="me-2 ms-2"
                />
                <span>
                  <!-- 常見問題 -->
                  {{ t('convDetail_commonProblem') }}
                </span>
              </template>

              <template v-else>
                <v-avatar
                  v-if="msg.customerAvatar"
                  :image="userInfo.domain + msg.customerAvatar"
                  size="25"
                  class="me-2 ms-2"
                />
                <v-avatar
                  v-else
                  color="pink"
                  size="25"
                  class="me-2 ms-2"
                >
                  <span>{{ historyInfo.chatConversation.customerName.substring(0, 2) }}</span>
                </v-avatar>

                <span>
                  {{ historyInfo.chatConversation.customerName }}
                </span>
              </template>
            </div>
          </template>

          <!-- 客服 -->
          <template v-if="(msg.group == 'right' && index == 0) || (msg.group == 'right' && index > 0 && msg.group !== historyInfo.chatMessage[index - 1].group)">
            <div class="msgTitle rightStyle">
              <!-- 常見問題 -->
              <template v-if="msg.msgType == 'common_problem'">
                <v-avatar
                  :image="'/images/answer.png'"
                  size="25"
                  class="me-2 ms-2"
                />
                <span>
                  <!-- 系統回覆 -->
                  {{ t('convDetail_systemAnswer') }}
                </span>
              </template>

              <template v-else>
                <template v-if="msg.agentAvatar">
                  <v-avatar
                    :image="userInfo.domain + msg.agentAvatar"
                    size="25"
                    class="me-2 ms-2"
                  />
                </template>
                <template v-else>
                  <v-avatar
                    color="pink"
                    size="25"
                    class="me-2 ms-2"
                  >
                    <span>{{ msg.userName && msg.userName.substring(0, 1) }}</span>
                  </v-avatar>
                </template>

                <span>
                  {{ msg.userName && msg.userName }}
                </span>
              </template>
            </div>
          </template>

          <!-- 對話內容 -->
          <div class="message" :class="{'rightStyle': msg.group == 'right' }">
            <!-- 系統提示語 -->
            <div
              v-if="msg.msgType === 'welcome' && msg.senderType == 'system'"
              class="msgContent systemMsg"
            >
              {{ msg.content }}
            </div>

            <div v-if="msg.contentType == 'image'">
              <!-- TODO 圖片 -->
            </div>
            <div v-else-if="msg.contentType == 'audio'">
              <!-- TODO 影片 -->
            </div>
            <div v-else-if="msg.contentType == 'video'">
              <!-- TODO 音檔 -->
            </div>
            <div v-else-if="msg.contentType == 'file'">
              <!-- TODO 檔案 -->
            </div>
            <div v-else class="msgContent" :class="msg.group == 'right'? 'rightMsg':'leftMsg'">
              <!-- 對話 -->
              {{ msg.content }}
            </div>

            <!-- 對話 -->
            <!-- <div
              v-if="msg.msgType == 'hello' || msg.msgType == 'message' || msg.msgType == 'common_problem'"
              class="msgContent"
              :class="msg.group == 'right'? 'rightMsg':'leftMsg'"
            >
              {{ msg.content }}
            </div> -->

            <!-- 時間 -->
            <span class="msgTime">{{ monthFormat(msg.createdAt) }}</span>
          </div>
        </div>

        <!-- 結束提示語 -->
        <div v-if="msg.msgType === 'finish' " class="finishMsg mt-10 mb-4">
          {{ msg.content }}
        </div>
      </template>
    </template>
    <template v-else>
      <div class="text-center">
        <!-- 無對話 -->
        {{ t('convDetail_convEmpty') }}
      </div>
    </template>
  </div>
</template>

<script setup>
const { t } = useI18n()

const userInfo = useUserInfo()
const historyInfo = useHistoryInfo()

// 查看更多對話
const numPage = ref(0)
const viewMoreChatInfo = async () => {
  numPage.value += 1

  const conversationsId = historyInfo.chatConversation.id
  const channelId = historyInfo.chatConversation.channelId

  if (historyInfo.totalChatInfo > numPage.value) {
    // 獲取歷史訊息
    await historyInfo.getChatHistoryMsg(numPage.value, conversationsId, channelId)
  }
}
</script>

<style lang="scss" scoped>
@import "assets/styles/pages/chat.scss";

.moreConInfo { // 查看更多歷史對話
  text-align: center;
  margin-bottom: 20px;
  button {
    background: #00BCD4;
    padding: 4px 20px;
    border-radius: 30px;
    color: #fff;
    &:hover {
        opacity: 0.8;
    }
  }
}
</style>
