<template>
  <div>
    <v-expansion-panels v-model="historyInfo.onCurrentPanelIndex">
      <v-expansion-panel v-for="conItem in historyInfo.historyConversation" :key="conItem.id" class="mt-4">
        <!-- 【全部】對話紀錄列表 -->
        <v-expansion-panel-title
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <div class="topInfo">
            <div class="title mb-3">
              {{ conItem.channelName }}
            </div>
            <!-- 進排隊 -->
            <div v-if="conItem.endReason === 'queue'" class="d-flex align-center subText">
              {{ monthFormat(conItem.queueInfo.startedAt) }}
              <div class="ms-6">
                <!-- 排隊結束 -->
                {{ t('convDetail_endReason_queue') }}
              </div>
            </div>
            <div v-else-if="conItem.endReason === 'queue_timeout'" class="d-flex align-center subText">
              {{ monthFormat(conItem.queueInfo.startedAt) }}
              <div class="ms-6">
                <!-- 排隊超時結束 -->
                {{ t('convDetail_endReason_queueTimeout') }}
              </div>
            </div>

            <!-- 進對話 -->
            <div v-else class="d-flex align-center subText">
              {{ monthFormat(conItem.startedAt) }}
              <div class="ms-6">
                <v-avatar
                  v-if="conItem.agentName && conItem.agentName.avatar"
                  :image="userInfo.domain + conItem.agentName.avatar"
                  size="18"
                  class="me-1"
                />
                <v-avatar v-else color="pink" size="18" class="me-1">
                  <span>{{ conItem.agentName && conItem.agentName.username.substring(0, 2) }}</span>
                </v-avatar>
                <span>{{ conItem.agentName? conItem.agentName.username:'' }}</span>
              </div>
            </div>
          </div>
        </v-expansion-panel-title>

        <!-- 展開內容 -->
        <v-expansion-panel-text class="mt-4">
          <template v-if="historyInfo.getOtherConInfoLoading">
            <div>
              <CommonProgressLoading />
            </div>
          </template>
          <template v-else>
            <!-- 對話資訊 -->
            <div class="mb-6">
              <div class="subTitle">
                <i class="fa-solid fa-pause me-1" />
                {{ t('convDetail_convInfo') }}
              </div>
              <div class="pb-5 d-flex align-center">
                <div class="me-5">
                  {{ t('convDetail_convInfo_convId') }}
                </div>
                <div>{{ historyInfo.chatConversation.id? historyInfo.chatConversation.id : '-' }}</div>
              </div>
              <div class="pb-5 d-flex align-center">
                <div class="me-5">
                  {{ t('convDetail_convInfo_region') }}
                </div>
                <!-- 國家、省份、城市 -->
                <div v-if="historyInfo.chatConversation.connectionInfo&&historyInfo.chatConversation.connectionInfo.region">
                  {{ historyInfo.chatConversation.connectionInfo.region.country }} {{ historyInfo.chatConversation.connectionInfo.region.province }} {{ historyInfo.chatConversation.connectionInfo.region.city }}
                </div>
                <div v-else>
                  -
                </div>
              </div>
              <div class="pb-5 d-flex align-center">
                <div class="me-5" style="min-width:74px;">
                  {{ t('convDetail_convInfo_satisfaction') }}
                </div>
                <div v-if="historyInfo.chatConversation.satisfaction">
                  <div class="satisfaction">
                    {{ satisfactionLevels[historyInfo.chatConversation.satisfaction.score] }}
                  </div>
                  <div class="mt-4 ms-2">
                    {{ historyInfo.chatConversation.satisfaction.remark }}
                  </div>
                </div>
                <div v-else>
                  -
                </div>
              </div>
              <div class="pb-5 d-flex align-center">
                <div class="me-5">
                  {{ t('convDetail_convInfo_convTag') }}
                </div>
                <div v-if="(historyInfo.chatConversation.conversationTags ?? []).length > 0">
                  <CommonChip
                    v-for="i in historyInfo.chatConversation.conversationTags"
                    :key="i.id"
                    :background-color="i.color"
                    :title="i.name"
                    class="mb-1 mr-2"
                  />
                </div>
                <div v-else>
                  -
                </div>
              </div>
              <v-divider />
            </div>

            <!-- 設備資訊 -->
            <div class="mb-6">
              <div class="subTitle">
                <i class="fa-solid fa-pause me-1" />
                {{ t('convDetail_deviceInfo') }}
              </div>
              <div class="pb-5 d-flex align-center">
                <div class="me-5">
                  {{ t('convDetail_deviceInfo_device') }}
                </div>
                <div>{{ historyInfo.chatConversation.connectionInfo&& historyInfo.chatConversation.connectionInfo.device }}</div>
              </div>
              <div class="pb-5 d-flex align-center">
                <div class="me-5">
                  {{ t('convDetail_deviceInfo_platform') }}
                </div>
                <div>{{ historyInfo.chatConversation.connectionInfo&& historyInfo.chatConversation.connectionInfo.platform }}</div>
              </div>
              <div class="pb-5 d-flex align-center">
                <div class="me-5">
                  {{ t('convDetail_deviceInfo_browser') }}
                </div>
                <div>{{ historyInfo.chatConversation.connectionInfo&& historyInfo.chatConversation.connectionInfo.browser }}</div>
              </div>
              <div class="pb-5 d-flex align-center">
                <div class="me-5">
                  {{ t('convDetail_deviceInfo_ip') }}
                </div>
                <div>{{ historyInfo.chatConversation.connectionInfo&& historyInfo.chatConversation.connectionInfo.ip }}</div>
              </div>
              <v-divider />
            </div>

            <!-- 對話紀錄 -->
            <div class="mb-6">
              <div class="subTitle">
                <i class="fa-solid fa-pause me-1" />
                {{ t('convDetail_convRecord') }}
              </div>
              <div class="chatMsgListBlock">
                <ConvDetailConvRecordChatList />
              </div>
            </div>
          </template>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script setup>
const { t } = useI18n()

const historyInfo = useHistoryInfo()
const userInfo = useUserInfo()

// 滿意度評價
const satisfactionLevels = computed(() => {
  return {
    1: t('common.satisfactionLevels_veryBad'), // 非常不滿意
    2: t('common.satisfactionLevels_bad'), // 不滿意
    3: t('common.satisfactionLevels_average'), // 一般
    4: t('common.satisfactionLevels_good'), // 滿意
    5: t('common.satisfactionLevels_excellent') // 非常滿意
  }
})

// 監聽 historyInfo.onCurrentPanelIndex
watch(() => historyInfo.onCurrentPanelIndex, (index) => {
  handlePanelChange(index)
})

// 切換面板事件
const handlePanelChange = async (index) => {
  if (index != null) {
    const conId = historyInfo.historyConversation[index].id
    await historyInfo.getConversationInfo(conId)
  }
}

// TODO 這邊要加入展開時，自動滾動到最上方
</script>

  <style lang="scss" scoped>
  .subTitle {
    font-size: 16px; font-weight: bold;
    margin-bottom: 15px;

    i {
      color: #00BCD4;
    }
  }

  // 滿意度評價
  .satisfaction {
    display: inline-block;
    background: #EBEBEB;
    padding: 4px 15px;
    border-radius: 30px;
  }

  // 對話紀錄
  .chatMsgListBlock {
    background: #F7F8FB;
    padding: 20px;
    border-radius: 10px;
  }

  :deep(.v-expansion-panel-text__wrapper) {
    position: relative;
    min-height: 200px;
  }
  </style>
